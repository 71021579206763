import React from "react";
import RequestButton from "../RequestButton";

const HighCholesterolInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          High Cholesterol Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">What is cholesterol?</p>
        <p className="text-md text-gray-600 mb-2">
          Cholesterol is fat your body makes to help with digestion and the
          creation of hormones and vitamin D. Some cholesterol is therefore
          vital for your health, but as your liver makes all the cholesterol you
          require, you don’t need any in your diet.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Most people do get extra cholesterol from what they eat, and often far
          too much. The key issue with cholesterol is that there are several
          types, and while some are good for you, others are potentially
          harmful.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are good and bad cholesterols?
        </p>
        <p className="text-md text-gray-600 mb-2">
          The good cholesterol that your body needs for proper functioning is
          high-density lipoprotein (HDL). HDL also gathers up bad cholesterol
          and takes it to your liver for processing.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The two bad cholesterols are low-density lipoprotein (LDL) and
          very-low-density lipoprotein (VLDL). These are the cholesterols that
          cause a build-up of plaque in your arteries, which leads to heart and
          circulation diseases.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Foods that contain high levels of LDL and VLDL include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Animal fats</li>
          <li>Dairy</li>
          <li>Fried foods</li>
          <li>Processed foods</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          If you eat a lot of these foods, there’s a good chance your
          cholesterol levels are too high. Smoking also increases bad
          cholesterol levels. If you don’t get enough exercise, that makes the
          problem worse, because lack of exercise reduces levels of HDL, the
          good cholesterol.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Why do I need to reduce my cholesterol levels?
        </p>
        <p className="text-md text-gray-600 mb-2">
          If your cholesterol levels are high, the plaque buildup in your
          arteries makes these major blood vessels narrower, reducing the flow
          of blood around your body. Reduced blood flow can cause serious and
          potentially life-threatening conditions like:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Angina</li>
          <li>Carotid artery disease</li>
          <li>Heart attack</li>
          <li>Peripheral arterial disease</li>
          <li>Stroke</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          One of the dangers of high cholesterol is that until it reaches a
          critical level, you won’t experience any symptoms. The only way to
          know you have high cholesterol before reaching a crisis point is to
          have a blood test at Sunstate Medical Associates.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How is high cholesterol treated?
        </p>
        <p className="text-md text-gray-600 mb-2">
          If your blood test results show you have high cholesterol, then making
          changes to your lifestyle is essential. Cut out fatty foods,
          especially those that have saturated and trans fats. Eat healthier
          meals, start exercising regularly, and lose weight if you can stand to
          shed some pounds.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The Sunstate Medical Associates team can help you with all these
          changes. They support you and provide the best information to help you
          improve your health and reduce cholesterol. You might also need to
          take medication, at least for a while, to help lower your cholesterol.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Find out how healthy your arteries are by having a cholesterol test at
          Sunstate Medical Associates, or schedule a consultation if you’re
          worried about your heart health. Call the office today and speak with
          a friendly team member or book an appointment online.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default HighCholesterolInfo;
