import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import HighCholesterolInfo from "../../components/services/HighCholesterolInfo";

const HighCholesterol = () => {
  return (
    <Layout>
      <PageHeader text="High Cholesterol" />
      <ServicesHeader>
        High cholesterol is a problem you won’t know you have until it causes a
        serious issue with your heart or circulation. At Sunstate Medical
        Associates in Lake Mary, Florida, the board-certified internists have
        considerable expertise in diagnosing high cholesterol and helping
        patients lower their cholesterol levels before there are serious
        complications. Book a simple blood test to check your cholesterol levels
        by calling Sunstate Medical Associates today or use the convenient
        online form.
      </ServicesHeader>
      <HighCholesterolInfo />
      <Conditions />
    </Layout>
  );
};

export default HighCholesterol;
